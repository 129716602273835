import * as React from "react"
import SearchTemplate from "../../../templates/search-template";
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import SEO from "../../../components/seo";
import axios from "axios";
import _ from "lodash";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            min: "",
            max: "",
            bedrooms: "",
            building: "",
            officeSlug: "",
            searchType: "sales",
            boundingBox: {},
            areaList:[],
            radius:3,
            radiusAvailable:false 
        }
    }
    setMatchArea = (area) => {
        this.setState({matchArea: area})
    }
    changeStateAndUrl = (searchState, matchArea) => {
        
        let urls = [];
        let toNavigate= true;
        let query = searchState.configure.query; 

        if(typeof searchState.boundingBox !== "undefined") {
            if(JSON.stringify(this.state.boundingBox) !== JSON.stringify(searchState.boundingBox)) {
                toNavigate = false;
                this.setState({ boundingBox: searchState.boundingBox })
            }
        }

       
        if(typeof query !== "undefined") {
            if(query !== "") {
                urls.push(`in-${query}`)
                this.setState({ query: query })
            } else {
                if(matchArea.area){
                    urls.push(`in-${matchArea.area}`)
                }
                this.setState({ query: query })
            }
        } else {
            urls.push("in-south-buckinghamshire")
        }

        //create radius URL
        if(matchArea.radiusAvailable) { 
            urls.push(`radius-${matchArea.radius}`)
            this.setState({ radiusAvailable: true, radius: matchArea.radius }) 
        } else{
            this.setState({ radiusAvailable: false, radius: 3 }) 
        }

        // create building URL
        if(typeof searchState.menu !== "undefined") {
            if(searchState.menu.building) {
                urls.push(`type-${searchState.menu.building}`)
                this.setState({ building: searchState.menu.building })
            } else {
                this.setState({ building: ""})
            }
        } else if(this.state.building !== "") {
            urls.push(`type-${this.state.building}`)
        }
         //create office URL
         if (typeof searchState.menu !== "undefined") {
            if (searchState.menu.officeSlug) {
                urls.push(`from-${searchState.menu.officeSlug}-estate-agents`)
                this.setState({ officeSlug: searchState.menu.officeSlug })
            }
            else {
                this.setState({ officeSlug: "" })
            }
        } else if (this.state.officeSlug !== "") {
            urls.push(`from-${this.state.officeSlug}-estate-agents`)
        }
        
        // create bedroom URL
        if(searchState.range && searchState.range.bedroom) {
            let bedroom = searchState.range.bedroom.min;
            if(bedroom) {
                urls.push(`${bedroom}-and-more-bedrooms`)
                this.setState({ bedrooms: bedroom })
            } else {
                this.setState({ bedrooms: "" })
            }
        }

        // create Price min and Max URL
        if(typeof searchState.range !== "undefined" && typeof searchState.range.price !== "undefined") {
            let price = searchState.range.price;
            let min = price.min;
            let max = price.max;
            if(min && max) {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if(min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else if(this.state.min !== "") {
                    this.setState({ min: ""})
                } 
                if(max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else if(this.state.max !== ""){
                    this.setState({ max: ""})
                }
            } 
        } else {
            let min = this.state.min;
            let max = this.state.max;
            if(min !== "" && max !== "") {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if(min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else {
                    this.setState({ min: ""})
                } 
                if(max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else {
                    this.setState({ max: ""})
                }
            } 
        }
        if(toNavigate) {
            let url = urls.join('/');
            let newRoute = '/property-map/for-sale/'+url;
            if (!newRoute.endsWith('/'))
                newRoute = newRoute + '/';
            navigate(newRoute)
        }
    }
    componentDidMount() {
        if(window)
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        const { params } = this.props;
        const searchParams = params['*'].split('/');
        searchParams.forEach(param => {
            if(param.includes("in-") && !param.includes("in-south-buckinghamshire")) {
                this.setState({ query: param.substring(3)})
            }
            if(param.includes("under-")) {
                this.setState({ max: param.substring(6)})
            }
            if(param.includes("over-")) {
                this.setState({ min: param.substring(5)})
            }
            if(param.includes("-and-more-bedrooms")) {
                this.setState({ bedrooms: param.split("-")[0]})
            }
            if(param.includes("type-")) {
                this.setState({ building: param.split("-")[1]})
            }
            if (param.includes("from-")) {
                this.setState({ officeSlug: param.split('from-').pop().split('-estate-agents')[0] })
            }
            if (param.includes("radius-")) { 
                this.setState({ radiusAvailable: true, radius: param.split('radius-')[1] })
            }
            if(param.includes("between-")) {
                this.setState({ 
                    min: param.split("-")[1],
                    max: param.split("-")[3]
                })
            }
        });
        this.getitems(`${process.env.GATSBY_STRAPI_SRC}/areas`);
    }
    getitems = async url => {
        try {
            const { data } = await axios.get(url)// could be from env files   
            this.setState({areaList : data}); 
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    changeToMapUrl = () => {
        let url = this.props.location.pathname.replace("/property/", "/property-map/");
        if (!url.endsWith('/'))
            url = url + '/';
        navigateGatsby(url)
    }
    changeToListUrl = () => {
        let url = this.props.location.pathname.replace("/property-map/", "/property/");
        if (!url.endsWith('/'))
            url = url + '/';
        navigateGatsby(url)
    }

    
    render() {
        var search_area = "South Buckinghamshire";
        var price_range = "", property_type = "", bedrooms = "", office_type = "";
        if(this.props.params["*"]) {
            search_area = this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in "+ this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "in South Buckinghamshire";
            price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("under-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("under-") || c.includes("between-"))[0].split("-").map(c => isNaN(c) ? c : `£${parseInt(c).toLocaleString()}`).join(" ") : "";
            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1]+"s" : "";
            office_type = this.props.params["*"].split("/").filter(c => c.includes("from-")).length > 0 ? _.upperFirst(this.props.params["*"].split("/").filter(c => c.includes("from-"))[0].split('from-').pop().split('-estate-agents')[0].split(/[-]+/).join(' ')): "";
            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";
        }
        if(this.state.radiusAvailable){
            search_area = `${search_area} within ${this.state.radius} miles`;
        }
        return (
            <>
                <SEO 
                    title={
                        (price_range !== "" && property_type !== "" && bedrooms !== "" && office_type !== "") ?
                        `${bedrooms} Bedroom ${property_type} for Sale ${search_area} ${price_range} from ${office_type} estate agents` :
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom ${property_type} for Sale ${search_area} ${price_range}` :
                        (property_type !== "" && bedrooms !== "" && office_type !== "") ?
                        `${bedrooms} Bedroom ${property_type} for Sale ${search_area} from ${office_type} estate agents` :
                        (price_range !== "" && bedrooms !== "" && office_type !== "") ?
                        `${bedrooms} Bedroom for Sale ${search_area} ${price_range} from ${office_type} estate agents` :
                        (price_range !== "" && property_type !== "" && office_type !== "") ?
                        `${property_type} for Sale ${search_area} ${price_range} from ${office_type} estate agents` :
                        (property_type !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom ${property_type} for Sale ${search_area}` :
                        (price_range !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom for Sale ${search_area} ${price_range}` :
                        (price_range !== "" && property_type !== "") ?
                        `${property_type} for Sale ${search_area} ${price_range}` :
                        (property_type !== "" && office_type !== "") ?
                        `${property_type} for Sale ${search_area} from ${office_type} estate agents` :
                        (price_range !== "" && office_type !== "") ?
                        `Property for Sale ${search_area} ${price_range} from ${office_type} estate agents` :
                        (bedrooms !== "") ?
                        `${bedrooms} Bedroom for Sale ${search_area}` :
                        (property_type !== "") ?
                        `${property_type} for Sale ${search_area}` :
                        (office_type !== "") ?
                        `Property from ${office_type} estate agents` :
                        (price_range !== "") ?
                        `Property for Sale ${search_area} ${price_range}` :
                        `Property for Sale ${search_area}`
                    } 
                    description={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `Check out ${bedrooms} bedroom ${property_type} for sale in South Buckinghamshire ${price_range} with Bovingdons, our experts help you find the perfect ${property_type} in South Buckinghamshire.`:
                        (property_type !== "" && bedrooms !== "") ?
                        `Discover the best ${bedrooms} bedroom ${property_type} for sale in South Buckinghamshire, Contact Bovingdons leading estate and letting agents in South Buckinghamshire.` :
                        (price_range !== "" && bedrooms !== "") ?
                        `Find ${bedrooms} bedroom property for sale in South Buckinghamshire with Bovingdons, You can book a free property valuation with us for accurate property valuations.`:
                        (price_range !== "" && property_type !== "") ?
                        `Here is the latest ${property_type} for sale in South Buckinghamshire for you. Contact Bovingdons, the leading estate and letting agents in the South Buckinghamshire today.`:
                        (bedrooms !== "") ?
                        `Browse ${bedrooms} bedroom property for sale in South Buckinghamshire. Bovingdons offers a wide range of Property for sale in South Buckinghamshire.`:
                        (property_type !== "") ?
                        `Looking for ${property_type} for sale in South Buckinghamshire? check here for the finest collection of Buckinghamshire property. property experts. Book an appointment with one of our specialists today.`:
                        (price_range !== "") ?
                        `Discover the wide range of Property for sale ${search_area} ${price_range} with Bovingdons. Book an appointment with one of our specialists today to find the perfect property for sale anywhere in South Buckinghamshire.`:
                        `Find the best property for Sale ${search_area}. Contact our property experts and get help to find Property for Sale in South Buckinghamshire`
                    } 
                />
                <SearchTemplate
                    {...this.state}
                    changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    location={this.props.location}
                    mapView={this.props.location.pathname.includes("/property-map")}
                />
            </>
        )
    }
}

export default Search;